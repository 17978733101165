<template>
  <div class="userAgreementBox">
    <DetailBox title="用户协议">
      <TextBox color="title" :bold="6" :font-size="30">《“HY”用户注册协议》</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        声明与承诺:<br />
        您通过签署以下的《“HY”会员服务协议》注册成为“HY”的会员。您在签署时已充分理解并同意本协议的所有条款，特别是其中第三条“重要提示”以及第八条“免责声明”部分。您保证在注册前满足“HY”对于会员资格的要求，包括但不限于会员需要年龄介于18周岁到65周岁之间，拥有完全的民事行为能力和权利能力，通过有效的身份证件进行注册（每一个有效身份证号对应唯一的“HY”的会员资格），具备相应的风险承担能力等。
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第一条 会员注册</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、您通过登陆“HY”APP（以下简称：HY）中的“HY”页面，进入注册程序。<br />
        2、您阅读并理解“HY注册”页面的《“HY”用户协议》（以下简称：本协议）。<br />
        3、您按照“HY注册”页面填写所需的相关注册信息后，勾选已阅读本协议并同意相应条款。经HY审核后确认您成为会员，本协议即生效。<br />
        4、根据法律规定，在您完成上述注册程序、成为HY的会员，即表示您同意并签署了本协议，自愿接受本协议的条款约束。此后，您不得以未阅读本协议内容作任何形式的抗辩，也不得以未签署书面协议为由否认本协议的法律效力。
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第二条 服务内容及费用收取 </TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、您可享有HY向会员提供的以下核心服务： <br />
        （1）算力委托管理； <br />
        （2）其它算力产品相关服务；<br />
        （3）本协议或HY页面载明的其他相关服务。<br />
        2、您可以根据实际需要选择上述服务。如因您违反会员规则或者因网络问题、黑客、病毒或密码被盗、泄露等非HY的原因，HY可以暂时停止、限制或改变上述部分服务内容，或提供新的服务内容。
        3、您在接受HY提供各项服务的同时，同意自动接受HY提供的各类信息服务。为此：
        （1）您在此授权HY可以向您手机发送商业信息。 （2）您选择不接受上述各类信息服务时，您可以选择申请取消该类服务。
        4、就HY向您提供的各类服务是否收取费用及具体的收费标准，应以HY官方渠道公布的费用规则为准，或由您与HY另行签署其他协议。
        5、您下载或使用HY时，您须自行准备相关设备并承担如下开支： （1）包括并不限于手机等上网装置；
        （2）包括并不限于手机流量费等上网费用。
        6、为防止他人冒用您的身份注册、使用HY的服务，HY可能会给您的手机发送短信进行验证，由此产生的短信费用由HY支付，您不需支付该费用。
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第三条 重要提示</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、HY只接受在您国籍所在国/地区有效的身份证件作为申请材料。您应是在您国籍所在国/地区具有完全民事行为能力和权利能力的自然人。如您不满足上述要求，不应注册成为HY会员以及使用HY的服务。<br />
        2、HY不承担对您会员资料实质审查的义务。如您不符合本协议第3.1条的规定，HY保留随时中止或终止您会员资格的权利。<br />
        3、您在此向HY保证，您已符合本协议第3.1条规定的会员条件，如因您不符合本协议第3.1条规定的会员条件或向HY提供虚假信息或承诺而导致您自己或HY或第三人蒙受任何损失，将由您承担全部责任及经济损失。<br />
        4、您的会员资格是您在HY唯一的有效身份标识。您在HY注册成功后，不得将HY的会员资格转让给第三方使用。<br />
        5、您在此确认：您在HY的一切行为，均代表您自已做出的行为并由您承担相应的法律后果。任何利用您的用户名及密码登陆HY的行为均将被视为您的行为。因此，您有义务谨慎妥善地保管您的用户名、密码等相关信息。HY对您的用户名、密码等相关信息的遗失或被盗所产生的后果不承担任何责任。<br />
        6、您有义务在HY注册时提供真实、完整、有效的资料，并保证诸如身份证件号码、电子邮件地址、联系电话、联系地址等内容的准确性及有效性。如您提供存在误导或者虚假的信息，由此产生的所有不利后果由您自行承担。此外，HY可能根据相关国家/地区的法律规定或者司法程序的要求，使用或者向第三方提供您的资料或信息。<br />
        7、如您变更HY的账户信息（包括但不限于电子邮件地址、联系电话、联系地址等），须及时登录HY的相关页面进行变更登记。如因您未及时登录HY进行变更登记，由此导致的一切损失，由您自行承担责任。<br />
        8、互联网络可能会发生模仿、仿冒HY登录页面的样式制作视觉感相似的界面，造成页面欺诈事件，该类欺诈行为往往会引诱用户输入账号和密码等信息。HY在此提醒您防范网络风险，不要向非HYAPP或任何其他人士透露您的平台账户信息。由此导致的一切损失，由您自行承担责任。<br />
        9、如您与HY的其他会员产生纠纷，根据具体情况HY可以协助双方协商寻求解决途径。<br />
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第四条 您的陈述与保证</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、您登录HY的行为应保证严格遵守您国籍所在国/地区法律、中国香港地区法律以及您使用的计算机终端所在国/地区法律，不通过HY从事任何危害国家安全、洗钱、套现、传销、欺诈、逃汇、恐怖主义等违法活动或其他有违社会公共利益或公共道德的行为。<br />
        2、您确认在签署本协议前已阅读本协议及同意相关页面的风险提示，并对该等风险有充分理解和预期，您自愿承担该等风险可能带来的一切责任和损失。<br />
        3、您承诺合法使用HY提供的各类服务，不得擅自登录进入HY未公开的系统，不得利用、窃取他人的账号和密码及个人信息等。您承诺不会通过任何方式不正当地使用HY。<br />
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第五条 通知</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、本协议以下的通知以HY相关页面公示方式作出。该等通知一经公示，即视为已经送达，并视为您已经收到并知晓该通知。<br />
        2、除公示方式外，HY还可以用其他方式向您个人发送通知，如向您注册时登记的手机号、电子邮箱或您个人账户中的站内消息系统发送通知。上述通知一经发送，即视为已经送达，并视为您已经收到并知晓该通知。<br />
        3、鉴于本协议第七条中所述通知的重要性，您承诺：您须经常的登录HY浏览站内消息系统等相关页面，并密切关注您在HY注册登记的手机号的短信以及电子邮箱的邮件。<br />
        4、您同意HY出于向您提供服务之目的，可以向您在HY注册登记的手机号、电子邮箱或您在HY的站内消息系统发送有关通知或提醒。若您设置不接收有关通知或提醒，则您有可能无法接受该等通知信息。您不得以您未收到或未阅读该等通知信息向HY提出主张或抗辩。<br />
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第六条 您的信息授权、维护与使用</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、HY收集和储存您的会员信息的目的：在于为您提高服务的效率和质量。HY通过以下方式收集或获取您的资料。<br />
        （1）您在HY注册登记的信息； <br />
        （2）您登录HY留下的行为痕迹；<br />
        （3）HY根据您登录HY留下的行为痕迹，自动分析出您的资料； <br />
        （4）其他合理的资料收集或获取方式。<br />
        2、您同意HY在业务运营中使用您的会员信息，包括但不限于： <br />
        （1）对您进行身份、信息核实。<br />
        （2）出于提供服务的需要，在隐去唯一性或者识别性信息后，在HY公示您的部分信息。<br />
        （3）出于提供服务的需要，向HY合作的第三方提供您的会员信息。<br />
        （4）对您留存在HY的信息进行内部使用、评估、分类或研究。<br />
        （5）HY可能通过您注册登记的手机号、电子邮箱等方式与您联络，向您发送通知或者传递HY相关的业务和管理信息。<br />
        （6）为配合相关国家/地区的司法机关、行政主管机关，HY可以依法调取、使用您在HY留存的信息和材料。<br />
        （7）为解决、调停您在HY与其他会员产生相关争议、纠纷，使用您在HY留存的相关信息。<br />
        （8）根据您与HY之间签署的其他协议的约定使用您的信息。<br />
        3、您可以申请授权HY帮助修改您在HY填写的一切个人资料。<br />
        4、如您违反本协议及HY相关规则，HY有权在尝试联系您后，在HY暂停或停止您的会员登录、使用功能并在数据库中删除您的个人资料。<br />
        5、您在HY注册登记的手机号、电子邮箱等信息，HY承诺，非经您的同意，除本协议及HY与您另行约定外，不会向任何第三方出租或出售您的上述信息。<br />
        6、您同意在此授权HY将您账户的相关信息和数据传递给第三方，用于第三方为您提供服务。HY会要求该第三方依法使用您的上述信息。如您不希望向第三方提供上述信息，请勿签署本协议。<br />
        7、在本协议第六条的情况下，将由该等第三方提供并就以上服务及数据安全独立承担责任。因该第三方服务或其使用您的信息产生的纠纷，或第三方服务违反相关法律法规，或您在使用第三方服务过程中遭受的损失，由您与第三方协商解决，HY不承担责任。<br />
        8、您的会员资格终止后，您在HY登记注册的手机号、电子邮箱等信息将可能与您在HY的账户随同注销，并由HY定期清理。<br />
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第七条 账户注销或限制访问、服务协议的终止</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、若您决定不再使用HY的服务，如有应付但未付的款项（包括但不限于服务费、违约金、管理费等），应立即付清。在您付清上述款项后，本协议方可终止，您的会员资格方可注销。<br />
        2、若您决定不再使用HY的服务，如有您在HY账户下仍有可用款项，在满足HY货币提取规则的前提下，您应尽快提取。上述可用款项全部提取后，本协议方可终止，您的会员资格方可注销。<br />
        3、在履行本协议第7.1条、第7.2条约定的程序后，您须登录HY的相关页面申请注销您的账户。经HY审核同意后，正式注销您在HY的账户。<br />
        4、若您违反本协议及HY相关规则，或根据相关国家/地区司法机关、政府行政主管部门的要求，HY可以限制、关闭您在HY的账户。<br />
        5、您在HY的注册账户的注销不代表您责任的终止，您仍应对在使用HY期间的行为承担责任。<br />
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第八条 免责声明</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、HY的页面可能涉及由第三方所有、控制或运营的其他网站或链接（以下简称：第三方网站）。您确认按照第三方网站的使用协议确定相关权利义务，第三方网站的内容、产品、广告和其他任何信息均由您自行判断并承担风险，与HY无关。<br />
        2、因不可抗力、云服务或网络运营商的原因或因黑客、病毒或密码被盗、泄露等非HY的原因导致HY提供的服务中断或对您在HY的个人数据及资料造成损失，HY不承担法律责任，但HY将尽可能减少因此给您造成的损失和影响。<br />
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title" :bold="5">第九条 其他</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        1、您与HY签署的协议以中文版本为准。<br />
        2、您同意，HY可根据运营需要不时修订本协议，并将协议变更情况在HYAPP上进行公示，该等变更自公告载明的生效时间开始生效，并成为本协议的一部分。
        <br />
        <br />
        <div style="text-align: right;">HY</div>
      </TextBox>
    </DetailBox>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {};
  },
  // created () {},
  mounted() {
    this.$store.commit('setBodyColor');
  },
  // methods: {},
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.content {
  width: 690px;
  background: @white;
  border-radius: 10px;
  padding: 40px 56px;
  line-height: 1.6;
}
</style>
